<template>
  <div class="lc-choose">
    <div class="lc-choose-close" @click="onClose">
      <div class="lc-choose-close-icon"></div>
    </div>
    <div class="lc-choose-heading">
      {{ $t('chooseFreeOrPlan.heading') }}
    </div>
    <div class="lc-choose-desc">
      {{ $t('chooseFreeOrPlan.description') }}
    </div>
    <div class="lc-choose-features">
      <div class="lc-choose-features-feature">
        <div class="lc-choose-features-feature-item">
          <div class="lc-choose-features-feature-item-icon"></div>
          <div>{{ $t('features.contactFormToCollectLeads') }}</div>
        </div>
        <div class="lc-choose-features-feature-item">
          <div class="lc-choose-features-feature-item-icon"></div>
          <div class="lc-choose-features-feature-item-text">{{ $t('features.screensharing') }}</div>
        </div>
      </div>
      <div class="lc-choose-features-feature">
        <div class="lc-choose-features-feature-item">
          <div class="lc-choose-features-feature-item-icon"></div>
          <div class="lc-choose-features-feature-item-text">{{ $t('features.appointmentBooking') }}</div>
        </div>
        <div class="lc-choose-features-feature-item">
          <div class="lc-choose-features-feature-item-icon"></div>
          <div class="lc-choose-features-feature-item-text">{{ $t('features.unlimitedVisitorIdentification') }}</div>
        </div>
        <div class="lc-choose-features-feature-item">
          <div class="lc-choose-features-feature-item-icon"></div>
          <div class="lc-choose-features-feature-item-text">{{ $t('features.liveContactViaQRCode') }}</div>
        </div>
        <div class="lc-choose-features-feature-item">
          <div class="lc-choose-features-feature-item-icon"></div>
          <div class="lc-choose-features-feature-item-text">{{ $t('features.ABTesting') }}</div>
        </div>
        <div class="lc-choose-features-feature-item">
          <div class="lc-choose-features-feature-item-icon"></div>
          <div class="lc-choose-features-feature-item-text">{{ $t('features.whitelabel') }}</div>
        </div>
      </div>
      <div class="lc-choose-features-feature">
        <div class="lc-choose-features-feature-item">
          <div class="lc-choose-features-feature-item-icon"></div>
          <div class="lc-choose-features-feature-item-text">{{ $t('features.assignmentRules') }}</div>
        </div>
        <div class="lc-choose-features-feature-item">
          <div class="lc-choose-features-feature-item-icon"></div>
          <div class="lc-choose-features-feature-item-text">{{ $t('features.advancedAnalytics') }}</div>
        </div>
        <div class="lc-choose-features-feature-item">
          <div class="lc-choose-features-feature-item-icon"></div>
          <div class="lc-choose-features-feature-item-text">{{ $t('features.privacySupport') }}</div>
        </div>
        <div class="lc-choose-features-feature-item">
          <div class="lc-choose-features-feature-item-icon"></div>
          <div class="lc-choose-features-feature-item-text">{{ $t('features.personalSetup') }}</div>
        </div>
      </div>
    </div>
    <div class="lc-choose-action">
      <div class="lc-choose-action-btn lc-choose-action-change" @click="onChangePlan">
        {{ $t('chooseFreeOrPlan.actions.changePlan') }}
      </div>
      <div class="lc-choose-action-btn lc-choose-action-free" @click="onChooseFree">
        {{ $t('chooseFreeOrPlan.actions.chooseFree') }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChooseFreeOrPricePlan',
  methods: {
    onClose() {
      this.$emit('close')
    },
    onChangePlan() {
      this.$emit('change-plan')
    },
    onChooseFree() {
      this.$emit('choose-free')
    }
  }
}
</script>
<style lang="scss" scoped>
.lc-choose {
  /* Mobile styles */
  @media only screen and (max-width: 910px) {
    width: calc(90vw);
    height: calc(90vh);
  }

  width: 900px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: 'Larsseit';
  font-style: normal;
  background: #f4faff;
  border-radius: 6px;

  &-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    width: 20px;
    height: 20px;

    &-icon {
      opacity: 0.7;
      background-color: #262629;
      position: absolute;
      right: 10px;
    }
    &-icon:hover {
      opacity: 1;
    }
    &-icon:before,
    &-icon:after {
      position: absolute;
      content: ' ';
      height: 20px;
      width: 2px;
      background-color: #262629;
    }

    &-icon:before {
      transform: rotate(45deg);
    }
    &-icon:after {
      transform: rotate(-45deg);
    }
  }

  &-action {
    display: flex;
    margin: 10px;

    @media only screen and (max-width: 650px) {
      flex-direction: column;
      margin-top: 20px;
    }

    &-btn {
      width: 193px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px 0 10px;
      cursor: pointer;
      @media only screen and (max-width: 650px) {
        margin: 20px 10px 0 10px;
      }
    }

    &-change {
      background: #3034f7;
      border-radius: 8px;
      font-weight: 700;
      font-size: 20px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.111111px;
      color: #ffffff;
      opacity: 0.8;
    }
    &-change:hover {
      opacity: 1;
    }
    &-free {
      background: none;
      border-radius: 8px;
      font-weight: 700;
      font-size: 20px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.111111px;
      color: #3034f7;
      opacity: 0.8;
    }
    &-free:hover {
      opacity: 1;
      background: #f7fdff;
    }
  }

  &-heading {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.177778px;
    color: #262629;
    padding: 0px 242px 0px 243px;
    @media only screen and (min-width: 760px) and (max-width: 910px) {
      padding: 0px 150px 0px 150px;
    }
    @media only screen and (min-width: 400px) and (max-width: 759px) {
      padding: 0px 80px 0px 80px;
    }
  }
  &-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.111111px;
    color: #53535b;
    padding: 0px 253px 0px 253px;
    margin-top: 20px;
    @media only screen and (min-width: 760px) and (max-width: 910px) {
      padding: 0px 150px 0px 150px;
    }
    @media only screen and (min-width: 400px) and (max-width: 759px) {
      padding: 0px 80px 0px 80px;
    }
  }
  &-features {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    @media only screen and (max-width: 650px) {
      flex-direction: column;
      margin-top: 20px;
    }

    &-feature {
      display: flex;
      flex-direction: column;
      position: relative;
      width: -webkit-fill-available;
      padding: 30px;

      @media only screen and (max-width: 650px) {
        padding: 10px;
      }

      &-item {
        position: relative;
        &-icon:before,
        &-icon:after {
          top: 6px;
          left: -10px;
          position: absolute;
          content: ' ';
          height: 10px;
          width: 2px;
          background-color: #f93232;
        }

        &-icon:before {
          transform: rotate(45deg);
        }
        &-icon:after {
          transform: rotate(-45deg);
        }

        &-text {
          width: max-content;
        }
      }
    }
  }
}
</style>
