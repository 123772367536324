var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lc-choose" }, [
    _c("div", { staticClass: "lc-choose-close", on: { click: _vm.onClose } }, [
      _c("div", { staticClass: "lc-choose-close-icon" }),
    ]),
    _c("div", { staticClass: "lc-choose-heading" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("chooseFreeOrPlan.heading")) + "\n  "),
    ]),
    _c("div", { staticClass: "lc-choose-desc" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.$t("chooseFreeOrPlan.description")) + "\n  "
      ),
    ]),
    _c("div", { staticClass: "lc-choose-features" }, [
      _c("div", { staticClass: "lc-choose-features-feature" }, [
        _c("div", { staticClass: "lc-choose-features-feature-item" }, [
          _c("div", { staticClass: "lc-choose-features-feature-item-icon" }),
          _c("div", [
            _vm._v(_vm._s(_vm.$t("features.contactFormToCollectLeads"))),
          ]),
        ]),
        _c("div", { staticClass: "lc-choose-features-feature-item" }, [
          _c("div", { staticClass: "lc-choose-features-feature-item-icon" }),
          _c("div", { staticClass: "lc-choose-features-feature-item-text" }, [
            _vm._v(_vm._s(_vm.$t("features.screensharing"))),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "lc-choose-features-feature" }, [
        _c("div", { staticClass: "lc-choose-features-feature-item" }, [
          _c("div", { staticClass: "lc-choose-features-feature-item-icon" }),
          _c("div", { staticClass: "lc-choose-features-feature-item-text" }, [
            _vm._v(_vm._s(_vm.$t("features.appointmentBooking"))),
          ]),
        ]),
        _c("div", { staticClass: "lc-choose-features-feature-item" }, [
          _c("div", { staticClass: "lc-choose-features-feature-item-icon" }),
          _c("div", { staticClass: "lc-choose-features-feature-item-text" }, [
            _vm._v(_vm._s(_vm.$t("features.unlimitedVisitorIdentification"))),
          ]),
        ]),
        _c("div", { staticClass: "lc-choose-features-feature-item" }, [
          _c("div", { staticClass: "lc-choose-features-feature-item-icon" }),
          _c("div", { staticClass: "lc-choose-features-feature-item-text" }, [
            _vm._v(_vm._s(_vm.$t("features.liveContactViaQRCode"))),
          ]),
        ]),
        _c("div", { staticClass: "lc-choose-features-feature-item" }, [
          _c("div", { staticClass: "lc-choose-features-feature-item-icon" }),
          _c("div", { staticClass: "lc-choose-features-feature-item-text" }, [
            _vm._v(_vm._s(_vm.$t("features.ABTesting"))),
          ]),
        ]),
        _c("div", { staticClass: "lc-choose-features-feature-item" }, [
          _c("div", { staticClass: "lc-choose-features-feature-item-icon" }),
          _c("div", { staticClass: "lc-choose-features-feature-item-text" }, [
            _vm._v(_vm._s(_vm.$t("features.whitelabel"))),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "lc-choose-features-feature" }, [
        _c("div", { staticClass: "lc-choose-features-feature-item" }, [
          _c("div", { staticClass: "lc-choose-features-feature-item-icon" }),
          _c("div", { staticClass: "lc-choose-features-feature-item-text" }, [
            _vm._v(_vm._s(_vm.$t("features.assignmentRules"))),
          ]),
        ]),
        _c("div", { staticClass: "lc-choose-features-feature-item" }, [
          _c("div", { staticClass: "lc-choose-features-feature-item-icon" }),
          _c("div", { staticClass: "lc-choose-features-feature-item-text" }, [
            _vm._v(_vm._s(_vm.$t("features.advancedAnalytics"))),
          ]),
        ]),
        _c("div", { staticClass: "lc-choose-features-feature-item" }, [
          _c("div", { staticClass: "lc-choose-features-feature-item-icon" }),
          _c("div", { staticClass: "lc-choose-features-feature-item-text" }, [
            _vm._v(_vm._s(_vm.$t("features.privacySupport"))),
          ]),
        ]),
        _c("div", { staticClass: "lc-choose-features-feature-item" }, [
          _c("div", { staticClass: "lc-choose-features-feature-item-icon" }),
          _c("div", { staticClass: "lc-choose-features-feature-item-text" }, [
            _vm._v(_vm._s(_vm.$t("features.personalSetup"))),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "lc-choose-action" }, [
      _c(
        "div",
        {
          staticClass: "lc-choose-action-btn lc-choose-action-change",
          on: { click: _vm.onChangePlan },
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("chooseFreeOrPlan.actions.changePlan")) +
              "\n    "
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "lc-choose-action-btn lc-choose-action-free",
          on: { click: _vm.onChooseFree },
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("chooseFreeOrPlan.actions.chooseFree")) +
              "\n    "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }